<template>
  <static-fullscreen-card @sendHeight="sendHeight($event)" :title="false">
    <v-row>
      <v-col cols="12" sm="6" class="pa-1">
        <TaskOther v-if="!loading" :height="contentHeight / 2" @click="onClickTask($event)" :rows="getTasks('other')" />
      </v-col>
      <v-col cols="12" sm="6" class="pa-1">
        <TaskLate v-if="!loading" :height="contentHeight / 2" @click="onClickTask($event)" :rows="getTasks('late')" />
      </v-col>
      <v-col cols="12" sm="6" class="pa-1" v-show="block.todayWork">
        <TaskWork
          v-if="!loading"
          :height="contentHeight / 2"
          @click="onClickTask($event)"
          @config="onClickConfig($event)"
          @hide="hideBlock('todayWork')"
          :rows="getTasks('work')"
        />
      </v-col>
      <v-col cols="12" sm="6" class="pa-1">
        <TaskGoods
          v-if="!loading"
          :height="contentHeight / 2"
          @click="onClickTask($event)"
          @config="onClickConfig($event)"
          :rows="data.filter((t) => t.section == 'goods')"
          :rows1="getTasks('goods')"
        />
      </v-col>
      <v-col cols="12" sm="6" class="pa-1">
        <LastReport v-if="!loading && $root.profile.role == 1000" :height="contentHeight / 2" />
      </v-col>
    </v-row>
    <task-dialog v-model="showTaskDialog" :id="idEdit"></task-dialog>
    <s-quick-setting-dialog v-if="showConfigDialog" v-model="showConfigDialog" :model="modelConfig" />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    taskDialog: () => import("../dialogs/taskDialog.vue"),
    TaskGoods: () => import("./tasksTodayGoods"),
    TaskWork: () => import("./tasksTodayWork"),
    TaskLate: () => import("./tasksTodayLate"),
    TaskOther: () => import("./tasksTodayOther"),
    LastReport: () => import("./lastReport"),
  },
  data() {
    return {
      showTaskDialog: false,
      showConfigDialog: false,
      contentHeight: 0,
      idEdit: 0,
      loading: false,
      data: [],
      modelConfig: [],
      block: { todayWork: true },
    };
  },
  async created() {
    await this.fitchData();
  },
  methods: {
    hideBlock(n) {
      this.block[n] = false;
      return true;
    },
    getTasks(s) {
      return this.data.filter((t) => t.section == s);
    },
    onClickConfig(e) {
      this.modelConfig = e;
      this.showConfigDialog = true;
    },
    onClickTask(e) {
      this.idEdit = e.id;
      this.$router.push({ name: "tasks_view", params: { id: e.id } });

      // this.showTaskDialog = true;
    },
    sendHeight(e) {
      this.contentHeight = e - 24;
    },
    async fitchData() {
      this.loading = true;
      const status = this.$store.getters["config/get"].models.tasks.statuses
        .filter((el) => {
          return !["ready", "cancel"].includes(el?.status);
        })
        .map((s) => {
          return s.value;
        });
      this.data = (
        await this.$axios.get("/mechti/tasks", {
          params: {
            sort: { key: "date_start", order: "ASC" },
            filters: {
              //user_id: this.$root.profile.id,
              //is_show_main: 1,
              status,
            },
          },
        })
      ).data.data;
      this.data.forEach((task) => {
        if (task.date_end < new Date().date) {
          task.section = "late";
        } else if (task.type == "construction-goods") {
          task.section = "goods";
        } else if (task.type == "construction-schedule") {
          task.section = "work";
        } else {
          task.section = "other";
        }
      });

      this.loading = false;
    },
  },
};
</script>
